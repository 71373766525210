


























import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  apps: API.Applications.GET[] = []; // Only stores the current page of applications!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyApp: API.Applications.GET | null = null; // Application to be modified/deleted.
  options = {
    apiName: 'applications',
    socketName: 'applicationModified',
    idStr: 'id',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    modifyComponent: Modify,
  };
  dayjs = dayjs;
}
