
















































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { API } from '@/types';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import Tooltip from '@/components/Admin/Tooltip.vue';
import { allScopes, apiModify } from '@/api';
import clone from 'clone';
import { removeDBTimestamps } from '@/util';

const defaultAppObject: API.Applications.POST = {
  scopes: [],
};

@Component({
  components: {
    ModifyDialog,
    TextInput,
    Tooltip,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.Applications.GET | undefined;
  app = clone(defaultAppObject);
  appId: number | null = null;
  err: boolean | string = false;
  tab = null;
  token: string | null = null;
  allScopes = allScopes;

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) {
      this.tab = null;
      if (val && this.modifyItem) {
        this.app = removeDBTimestamps(this.modifyItem);
        this.token = this.modifyItem.token || null;
        this.appId = this.modifyItem.id;
      }
    }
  }

  async generateNewToken(): Promise<void> {
    if (this.modifyItem) {
      const { data } = (await apiModify('applications', this.modifyItem.id, { newToken: true }));
      this.$emit('post', data);
      this.token = data.token || null;
    }
  }

  cancel(): void {
    this.app = clone(defaultAppObject);
    this.token = null;
    this.appId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      this.app.scopes = this.app.scopes?.filter((s) => allScopes.includes(s));
      const { data } = await apiModify('applications', this.appId, this.app);
      this.app = clone(defaultAppObject);
      this.appId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
